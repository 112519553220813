import React from "react"
import moment from "moment"

import "./index.css"

const DateCircle = ({node_date}) => {

    const date_moment = moment(node_date)
    const date = {
        y: date_moment.format("Y"),
        m: date_moment.format("MMM").toUpperCase(),
        d: date_moment.format("D")
    }

    return (
        <div className="circle-date tc-text">
            <div className="circle-date-inner">
                <span className="circle-date-month">{date.m}</span>
                <span className="circle-date-day">{date.d}</span>
                <span className="circle-date-year">{date.y}</span>
            </div>
        </div>
    )
}

export default DateCircle