import React from "react";
import {Consumer} from "src/utils/contexts"
import Horizontal from "src/components/Element/horizontal"

import makePostListItemArray from "src/utils/make_post_list_item_array"

import "./index.css"

const PostListHome = ({posts, mode}) => {

    return (
        <Consumer>
            {({store, functions}) => (
                <>
                    {(() => {
                        const post_list_jsx = makePostListItemArray(store, posts)

                        return (
                            <>
                                <div className={"article-list-pc"}>
                                    <Horizontal save_scroll_position={true}>
                                        {post_list_jsx}
                                    </Horizontal>
                                </div>
                                <div className={"article-list-sp"}>
                                    {post_list_jsx}
                                </div>
                            </>
                        )
                    })()}
                </>
            )}
        </Consumer>
    )
}

export default PostListHome
