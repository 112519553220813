import {Link} from "gatsby";
import React from "react";
import moment from "moment";
import BackgroundImage from 'gatsby-background-image'
import DateCircle from "src/components/Element/date_circle"

const makePostListItemArray = (store, posts) => {
    const post_list = []
    posts.forEach((node) => {
        if (node === undefined || node.date === null || ! node.thumbnail) {
            return <></>
        }
        const apiURL = store.config.api_uri
        const post_date = moment(node.date)

        let thumbnail_fluid

        if(node.thumbnail) {
            thumbnail_fluid = node.thumbnail.childImageSharp.fluid
        } else {
            return <></>
        }

        const content_text = node.content.replace(/\/uploads/g, apiURL + "/uploads").
                                          replace(/<img src=/g, '<img class="lazyload" data-src=')
        // const content_text = node.content

        const link_array = [
            post_date.format("Y"),
            post_date.format("MM"),
            post_date.format("DD"),
            node.uri
        ]
        const link = "/" + link_array.join("/")

        post_list.push(
            // <div className="al-item" key={"al-item-" + node.id}>
            <div className="al-item" key={"al-item-" + node.id}>
                <Link className="al-item-link" to={link}>
                    <h1 className="al-item-title f-noto">{node.title}</h1>
                    <div className="al-item-content">
                        <div className="al-item-content-inner">
                            <div className="al-item-inside">
                                <div className="al-item-i-icon">
                                    {thumbnail_fluid ?
                                    <BackgroundImage
                                        Tag="div"
                                        className="al-item-i-icon-img"
                                        fluid={node.thumbnail.childImageSharp.fluid}
                                    >
                                    </BackgroundImage>
                                    : null}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="al-item-date">
                        <DateCircle node_date={node.date} />
                    </div>
                    
                </Link>
                <div className="related-list-bottom-mask" />
                <div className="related-list-bottom-line" />
            </div>
        )
    })

    return post_list
}

export default makePostListItemArray
