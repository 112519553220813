import React from "react"
import Cookies from 'js-cookie'

import "./index.css"

export default class Horizontal extends React.Component {

    constructor(props) {
        super(props)
        this.ref_wrapper = React.createRef()
        this.ref_inner = React.createRef()
    }

    state = {
        scrollFlg: true,
        scrollLeft: 0,
        counter1: 0,
        counter2: false
    }

    componentWillMount = () => {
        if(this.props.save_scroll_position) {
            // 現在のスクロール位置をクッキーから読み込む
            const previous_scroll_position = Cookies.get("scroll_position")
            if( ! isNaN(previous_scroll_position)) {
                this.setState({scrollLeft: previous_scroll_position})
            }
        }
    }

    componentWillUnmount = () => {
        if(this.props.save_scroll_position) {
            // 現在のスクロール位置をクッキーに保存しておく
            Cookies.set("scroll_position", this.state.scrollLeft, {expires: 1/24})
        }
    }

    handleScroll = (e) => {
        this.scrollHorizontal(e)
    }

    wheelStart = (e) => {
        this.setState({scrollFlg: false})
        this.wheelAct(e)
        return false
    }

    wheelAct = (e) => {
        const that = this
        that.scrollHorizontal(e)
        setTimeout(function(){
            if (that.state.counter2 === that.state.counter1) {
                // that.scrollHorizontal(e)
                that.wheelEnd()
            } else {
                that.setState({counter2: that.state.counter1})
                // that.scrollHorizontal(e)
                that.wheelAct(e)
            }
        }, 50);
    }

    wheelEnd = () => {
        this.setState({scrollFlg: true})
        this.setState({counter1: 0})
        this.setState({counter2: false})
    }

    scrollHorizontal = (e) => {
        const wrapper = this.ref_wrapper.current
        const inner = this.ref_inner.current

        let delta = 0;
        const deltaY = e.deltaY
        const deltaX = e.deltaX
        if(Math.abs(deltaY) > Math.abs(deltaX)) {
            delta = deltaY
        } else {
            // delta = deltaX
            return false
        }
        // delta = deltaY
        const newScrollLeft = this.state.scrollLeft - delta

        if(inner.offsetLeft + newScrollLeft >= 0 && delta < 0) {
            // on left end, and try going more left, we have to stop
            this.setState({scrollLeft: 0})
        // } else if(inner.offsetWidth + inner.offsetLeft + newScrollLeft <= wrapper.offsetWidth && delta > 0) {
        //     
        //     this.setState({scrollLeft: -(inner.offsetWidth - wrapper.offsetWidth)})
        } else if(inner.offsetWidth <= wrapper.offsetWidth - newScrollLeft) {
            // on right end, and try going more right, we have to stop
            // nothing do
        } else {
            // on other area
            this.setState({scrollLeft: newScrollLeft})
        }
    }

    toggleNoScroll = () => {

    }

    render() {
        return (
            <div className={"horizontal"}
                 ref={this.ref_wrapper}
                 onWheel={this.handleScroll}
            >
                <div className={"horizontal-inner"}
                     ref={this.ref_inner}
                     style={{"left": this.state.scrollLeft + "px"}}
                >
                    {this.props.children}
                </div>
            </div>
        )
    }
}
