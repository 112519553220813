import React from "react"
import {graphql} from "gatsby"
import {Consumer} from "src/utils/contexts"
import SEO from "src/components/Element/seo"

import PostListHome from "src/components/Domain/Post/post_list_home"

const TagPostList = ({data, location}) => {
    const siteTitle = data.site.siteMetadata.title
    const config = data.allStrapiConfig.edges[0].node
    const posts = data.allStrapiPost.edges

    let posts_for_show = []
    posts.forEach((obj) => {
        posts_for_show.push(obj.node)
    })

    const tagName = decodeURI(location.pathname.replace("/tag/", "")).replace(/\/$/, '')

    return (
        <Consumer>
            {({store, functions}) => (
                <>
                    <SEO
                        title={tagName + " - " + siteTitle}
                        description={tagName + "のタグのついた記事一覧。" + config.site_description}
                    />
                    <div id="home" className="main-content">
                        <div className="tag-name">
                            <span>{ tagName }</span>
                        </div>
                        <div className="article-list-wrapper post-by-tags">
                            <PostListHome posts={posts_for_show} />
                        </div>
                    </div>
                </>
            )}
        </Consumer>
    )
}

export default TagPostList

export const pageQuery = graphql`
    query PostByTagName($Name: String!) {
        site {
            siteMetadata {
                title
            }
        }
        allStrapiPost(
            filter: {
                visible: {eq: true}
                tags: {
                  elemMatch: {
                    Name: {eq: $Name}
                  }
                }
            }
        ) {
            edges {
                node {
                    id
                    title
                    date
                    content
                    uri
                    thumbnail {
                        childImageSharp {
                            fluid(maxWidth: 480) {
                                ...GatsbyImageSharpFluid_withWebp
                            }
                        }
                    }
                    tags {
                        id
                        Name
                        visible
                    }
                    visible
                }
            }
        }
        allStrapiConfig {
            edges {
                node {
                    about_text,
                    site_description
                }
            }
        }
    }
`
